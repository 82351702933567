import { injectCookiesFromLocalStorage } from '../functional-components/ShoppingCart/index.utils'
import { useShoppingCartContext } from '../functional-components/ShoppingCart/context/ShoppingCartContext'
import { useDeliveryContext } from '../functional-components/StoreSelector/context/DeliveryContext'
import { createOrderV1, createOrderV2, createOrderV3 } from '../common/clients/postCreateOrder'
import constants from '../common/constants'
import { getUserWalmartIdAndEmail } from '../common/ProfileUtils'
import { getStoreFromLocalStorage } from '../../src/functional-components/ShoppingCart/utils'
import { getJSONItem } from '../Helpers/localStorageHelper'

import getConfig from '../../src//Helpers/getConfig'

const {
  CHECKOUT_MESSAGE_ERROR: { ERROR_NO_REDIRECT_CHECKOUT_URL },
} = constants

const isWalstore = getConfig('isWalstore')

const getProductQuantity = (item) => Math.round(item.quantity * item.defaultQuantity * 100) / 100

function useCheckout() {
  const { shoppingCartItems, store } = useShoppingCartContext()
  const { dispatchType, address } = useDeliveryContext()

  const handleCheckout = async ({ walmartId, email }) => {
    if (address) delete address.id
    const createOrderParams = {
      walmartId,
      email,
      products: shoppingCartItems.map((item) => ({
        ID: item.ID.toString(),
        sku: item.sku.toString(),
        quantity: getProductQuantity(item),
      })),
      storeId: store.id,
      dispatchType,
      address,
    }
    const response = await createOrderV2(createOrderParams)
    if (response.redirectURL === undefined) {
      throw new Error()
    }

    return response.redirectURL
  }
  const handleCheckoutCatex = async ({ selectedWalstoreStore, pickupStore }) => {
    if (address) delete address.id
    const storeId = isWalstore ? selectedWalstoreStore?.id : pickupStore?.id

    const orderToCreate = {
      products: shoppingCartItems.map((product) => ({
        ID: product.ID.toString(),
        sku: product.sku.toString(),
        quantity: product.quantity,
        sellerId: product?.winningOffer?.sellerId ? product.winningOffer.sellerId.toString() : undefined,
      })),
      storeId,
    }
    //createOrder(products, urlOrder, isWalstore, creationStore)
    const response = await createOrderV1({ orderToCreate, selectedWalstoreStore, isWalstore })

    if (!response.redirectURL) {
      throw { response, message: ERROR_NO_REDIRECT_CHECKOUT_URL }
    }

    injectCookiesFromLocalStorage()
    return response.redirectURL
  }

  const handleCheckoutV3 = async ({ walmartId }) => {
    const storeLocalStorage = getStoreFromLocalStorage()
    const addressLocalStorage = getJSONItem(constants.delivery.STORAGE_KEY_ADDRESS)
    if (addressLocalStorage) delete addressLocalStorage.id
    const createOrderParams = {
      walmartId,
      products: shoppingCartItems.map((item) => ({
        ID: item.ID.toString(),
        sku: item.sku.toString(),
        quantity: getProductQuantity(item),
      })),
      storeId: storeLocalStorage.id,
      dispatchType,
      address: addressLocalStorage,
    }
    const response = await createOrderV3(createOrderParams)
    if (response.redirectURL === undefined) {
      throw new Error()
    }
    return response.redirectURL
  }

  const goToCheckout = async (isOriginIntegrationEnable) => {
    const redirectURL = isOriginIntegrationEnable
      ? await handleCheckoutV3(getUserWalmartIdAndEmail())
      : await handleCheckout(getUserWalmartIdAndEmail())

    injectCookiesFromLocalStorage()
    window.location.href = redirectURL
  }

  return { handleCheckout, handleCheckoutV3, goToCheckout, handleCheckoutCatex }
}

export default useCheckout
