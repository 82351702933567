import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { createSessionCookie } from '../../Helpers/sessionHelper'
import { getJSONItem, saveItemAsJSON } from '../../Helpers/localStorageHelper'
import { saveProfile } from '../../common/ProfileUtils'
import texts from '../../common/texts.json'
import constants from '../../common/constants'
import { emailValidationFunction, getConfigData } from '../../Helpers'
import useLogin from '../../hooks/useLogin'
import { postAddAddress } from '../../common/clients/postDeliveryAddresses'
import InnerForm from './components/InnerForm'
import LoginErrorPage from './components/ErrorPage'
import urlToggleHelper from '../../Helpers/urlToggleHelper'
import { formatAddress } from '../../Helpers/addressHelper'
import { logEventToDataCollection } from '../../data-tagging'
import { EVENTS, ORIGINS, FLOW_TYPES } from '../../data-tagging/constants'
import { Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import updateAuth from '../../actions/updateAuth'
import './index.css'

const CONFIRM_ACCOUNT_PATH = '/login-info-validacion'

const LoginForm = ({ onSuccessLogin, onClosePanel, analyticsOrigin }) => {
  const [userInput, setUserInput] = useState('')
  const [userInputPass, setUserInputPass] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [isShowErrorAlert, setIsShowErrorAlert] = useState(false)
  const [alertToShow, setAlertToShow] = useState('')
  const [isShowErrorPage, setIsShowErrorPage] = useState(false)
  const [isUnconfirmedAccountError, setIsUnconfirmedAccountError] = useState(false)
  const dispatch = useDispatch()

  const { reCaptchaEnterpriseValidationCode: reCaptchaValidationCode } = getConfigData()
  const refReCaptcha = useRef()

  const { onLogin } = useLogin()
  const { loginUrl, passwordRecoveryUrl, registerUrl, isOriginIntegrationEnable } = urlToggleHelper()

  const errorKeysToShowInAlert = [
    constants.LOGIN_ERROR_RESPONSES.USER_ID_OR_PASSWORD_IS_INCORRECT,
    constants.LOGIN_ERROR_RESPONSES.INVALID_CREDENTIALS,
    constants.LOGIN_ERROR_RESPONSES.BLOCKED_ACCOUNT,
    constants.LOGIN_ERROR_RESPONSES.INVALID_CAPTCHA,
    constants.LOGIN_ERROR_RESPONSES.USER_NOT_FOUND,
  ]

  const handleUserInputChange = (event) => {
    setUserInput(event.target.value)
  }

  const handleUserPassChange = (event) => {
    setUserInputPass(event.target.value)
    setErrors((prev) => ({ ...prev, PASSWORD_NOT_EMPTY: null }))
  }

  const handleUserInputOnBlur = (event) => {
    if (!userInput) {
      setErrors((prev) => ({ ...prev, EMAIL_NOT_EMPTY: texts.LOGIN.EMAIL_NOT_EMPTY }))
    } else if (!emailValidationFunction(event.target.value)) {
      setErrors((prev) => ({ ...prev, EMAIL_VALIDATION_ERROR: texts.LOGIN.EMAIL_VALIDATION_ERROR }))
    }
  }

  const keyToShowInError = () => errorKeysToShowInAlert.find((errorKey) => (errorKey in errors ? errorKey : undefined))

  useEffect(() => {
    if (userInput) {
      const isEmailValid = emailValidationFunction(userInput)
      const emailValidationErrorText = isEmailValid ? null : texts.LOGIN.EMAIL_VALIDATION_ERROR
      setErrors((prev) => ({ ...prev, EMAIL_VALIDATION_ERROR: emailValidationErrorText, EMAIL_NOT_EMPTY: null }))
    }
  }, [userInput])

  useEffect(() => {
    setIsShowErrorAlert(false)
    const errorToShow = keyToShowInError()
    if (errorToShow && errors[errorToShow]) {
      setAlertToShow(errors[errorToShow])
      setIsShowErrorAlert(true)
    }
  }, [errors])

  const isValidForm = () => {
    const emailNotEmpty = !userInput ? texts.LOGIN.EMAIL_NOT_EMPTY : null
    const passNotEmpty = !userInputPass ? texts.LOGIN.PASSWORD_NOT_EMPTY : null
    setErrors((prev) => ({
      ...prev,
      EMAIL_NOT_EMPTY: emailNotEmpty,
      PASSWORD_NOT_EMPTY: passNotEmpty,
    }))
    return userInput && userInputPass && emailValidationFunction(userInput)
  }

  const manageFormErrors = (error) => {
    if (texts.LOGIN[error]) {
      setErrors({
        [error]: texts.LOGIN[error],
      })
      setIsLoading(false)
    } else if (error === constants.LOGIN_ERROR_RESPONSES.EMAIL_UNCONFIRMED_BY_CUSTOMER) {
      tagEvent(EVENTS.LOGIN.VIEW_CONFIRM_ACCOUNT, { email: userInput })
      setIsUnconfirmedAccountError(true)
    } else {
      setIsShowErrorPage(true)
    }
  }

  const saveAddress = async () => {
    const localStorageSelectedAddress = getJSONItem(constants.delivery.STORAGE_KEY_ADDRESS)

    if (localStorageSelectedAddress) {
      const response = await postAddAddress(formatAddress(localStorageSelectedAddress))
      saveItemAsJSON(constants.delivery.STORAGE_KEY_ADDRESS, {
        ...localStorageSelectedAddress,
        id: response?.id,
      })
    }
  }

  const tagEvent = (eventName, data) => {
    logEventToDataCollection({
      origin: analyticsOrigin,
      flowType: FLOW_TYPES.LOGIN,
      eventName,
      data,
    })
  }

  const loginUser = async () => {
    const reCaptchaResultToken = await refReCaptcha.current.executeAsync()
    const response = await onLogin(userInput, userInputPass, reCaptchaResultToken, loginUrl, isOriginIntegrationEnable)
    const isLoginSuccess = isOriginIntegrationEnable ? response.success : response.token

    if (isLoginSuccess) {
      const profile = isOriginIntegrationEnable
        ? {
            firstName: response.firstName,
            walmartId: response.customer.walmartId,
            customer: { walmartId: response.customer.walmartId },
          }
        : response.customerInfo
      saveProfile(profile)
      dispatch(updateAuth())
      tagEvent(EVENTS.LOGIN.SUCCESS, { email: userInput })

      saveAddress()
      onSuccessLogin(response.customer)

      if (!isOriginIntegrationEnable) {
        createSessionCookie(response)
      }
    } else {
      tagEvent(EVENTS.LOGIN.ERROR, { email: userInput, error: response.message })
      manageFormErrors(response.message)
    }
  }

  const handleSubmit = async (e) => {
    setErrors({ cleanErrors: true })
    e.preventDefault()
    setIsLoading(true)

    if (!isValidForm()) {
      setIsLoading(false)
      return
    }
    setIsLoading(true)
    tagEvent(EVENTS.LOGIN.SUBMIT, { email: userInput })

    try {
      loginUser()
    } catch (error) {
      setIsShowErrorPage(true)
    }
  }

  const innerFormCtx = {
    alertToShow,
    userInput,
    userInputPass,
    errors,
    refReCaptcha,
    reCaptchaValidationCode,
    isLoading,
    isShowErrorAlert,
    handleUserInputChange,
    handleUserInputOnBlur,
    handleUserPassChange,
    handleSubmit,
    recoveryPassUrl: passwordRecoveryUrl,
    registerUrl,
    isOriginIntegrationEnable,
    analyticsOrigin,
  }

  if (isUnconfirmedAccountError) {
    return <Redirect to={{ pathname: CONFIRM_ACCOUNT_PATH, state: { email: userInput } }} />
  }

  return (
    <div>{isShowErrorPage ? <LoginErrorPage onClosePanel={onClosePanel} /> : <InnerForm ctx={innerFormCtx} />}</div>
  )
}

LoginForm.defaultProps = {
  onClosePanel: null,
  analyticsOrigin: ORIGINS.LOGIN_VIEW,
}

LoginForm.propTypes = {
  onSuccessLogin: PropTypes.func.isRequired,
  onClosePanel: PropTypes.func,
  analyticsOrigin: PropTypes.string,
}

export default LoginForm
