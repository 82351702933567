import { UPDATE_AUTH } from '../types'
import constants from '../common/constants'
import { getJSONItem } from '../Helpers/localStorageHelper'

const initState = {
  user: getJSONItem(constants.profile.PROFILE),
}
export default (state = initState, action) => {
  switch (action.type) {
    case UPDATE_AUTH:
      return { ...state, user: getJSONItem(constants.profile.PROFILE) }
    default:
      return state
  }
}
