import React, { useRef } from 'react'
import { Button, Popover, Link } from '@walmart-web/livingdesign-components'
import { User as UserIcon, SignOut as SignOutIcon } from '@walmart-web/livingdesign-icons'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import texts from '../../../common/texts.json'
import { getUserFirstName, clearProfile, clearDispatchType, clearSelectedStore } from '../../../common/ProfileUtils'
import { userFirstNameTruncate } from '../../../Helpers'
import { deleteSODSessionToken } from '../../../Helpers/sessionHelper'
import UserLoggedInIcon from './UserLoggedInIcon'
import { useHeaderContext } from '../context/HeaderContext'
import urlToggleHelper from '../../../Helpers/urlToggleHelper'
import { useShoppingCartContext } from '../../ShoppingCart/context/ShoppingCartContext'
import { useDeliveryContext } from '../../StoreSelector/context/DeliveryContext'
import useWindowSize from '../../../hooks/useWindowSize'
import postLogout from '../../../common/clients/postLogoutClient'
import updateAuth from '../../../actions/updateAuth'
import './LoginMenu.css'

const LoginMenu = () => {
  const ref = useRef()
  const { registerUrl, isOriginIntegrationEnable } = urlToggleHelper()
  const userFirstName = getUserFirstName()
  const { loginMenuIsOpen, setLoginMenuIsOpen } = useHeaderContext()
  const dispatch = useDispatch()

  const { setStore } = useShoppingCartContext()
  const { setDispatchType, clearAddresses } = useDeliveryContext()
  const { width: innerWidth } = useWindowSize()

  const toogleVisible = () => {
    setLoginMenuIsOpen((currentLoginMenuIsOpen) => !currentLoginMenuIsOpen)
  }

  const closeEvent = () => {
    setLoginMenuIsOpen(false)
  }

  const closeSessionHandler = () => {
    deleteSODSessionToken()
    clearProfile()
    clearDispatchType()
    dispatch(updateAuth())
    setDispatchType(null)
    toogleVisible()
    setStore(undefined)
    clearSelectedStore()
    clearAddresses()
    postLogout()
  }

  const leadingUserIcon = () => (userFirstName ? <UserLoggedInIcon /> : <UserIcon size="medium" />)

  const leadingSignOutIcon = () => <SignOutIcon size="small" />

  const createAccountButton = () => {
    return (
      <Button variant="secondary" size="medium" isFullWidth>
        {texts.LOGIN.CREATE_ACCOUNT_LABEL}
      </Button>
    )
  }

  const renderPopoverLoginMenu = () => (
    <div className="login-menu__popover-content" data-testid="login-menu-content">
      <NavLink to="/login" className="login-menu__popover-content__link">
        <Button isFullWidth variant="primary" size="medium" className="login-menu__popover-content__button">
          {texts.LOGIN.LOGIN_LABEL}
        </Button>
      </NavLink>

      {isOriginIntegrationEnable ? (
        <NavLink to={registerUrl} className="login-menu__popover-content__link">
          {createAccountButton()}
        </NavLink>
      ) : (
        <Link href={registerUrl} className="login-menu__popover-content__link">
          {createAccountButton()}
        </Link>
      )}
    </div>
  )

  const renderPopoverSessionMenu = () => (
    <div className="login-menu__popover-content--logged" data-testid="session-menu-content">
      <Button variant="tertiary" size="medium" leading={leadingSignOutIcon()} onClick={closeSessionHandler}>
        {texts.USER.LOG_OUT}
      </Button>
    </div>
  )

  return (
    <div className="login-menu">
      <Popover
        content={userFirstName ? renderPopoverSessionMenu() : renderPopoverLoginMenu()}
        onClose={closeEvent}
        isOpen={loginMenuIsOpen}
        triggerRef={ref}
        data-testid={`${userFirstName ? 'session' : 'login'}-menu-options`}
      >
        <Button
          className={`login-menu__toogle ${loginMenuIsOpen ? 'login-menu__toogle--visible' : ''}`}
          size="medium"
          variant="primary"
          leading={leadingUserIcon()}
          ref={ref}
          onClick={toogleVisible}
          data-testid={`${userFirstName ? 'session' : 'login'}-menu-button`}
        >
          {userFirstNameTruncate(innerWidth, userFirstName || texts.LOGIN.SIGN_IN)}
        </Button>
      </Popover>
    </div>
  )
}

export default LoginMenu
