import React, { useEffect } from 'react'

import ShoppingCartIcon from '../../ShoppingCart/icon/ShoppingCartIcon'
import { useLateralPanelContext } from '../context/LateralPanelContext'
import { useShoppingCartContext } from '../../ShoppingCart/context/ShoppingCartContext'

import getPromotionItemsEvaluate, {
  evaluateItmesPromotionsService,
} from '../../../common/clients/promotionEngineClient'
import {
  getSkusToAddToCartForHomeReplenishment,
  getAndMergeCart,
  updateItemsInformation,
} from '../../ShoppingCart/utils'
import constants from '../../../common/constants'

const ShoppingCartHeaderIcon = () => {
  const { saveShoppingCart } = useShoppingCartContext()
  const { openShoppingCart, showPromotionsValidation } = useLateralPanelContext()
  const url = window?.location?.search
  const itemsToAddToCart = getSkusToAddToCartForHomeReplenishment(url)

  async function updateItemsInStorage(mergedCart) {
    if (window.__ENV__?.enableCallProductCatalogForHR) {
      const updatedItemsInformation = await updateItemsInformation(
        mergedCart,
        constants.HOME_REPLENISHMENT.DEFAULT_STORE_ID
      )
      if (updatedItemsInformation?.length > 0) {
        saveShoppingCart(updatedItemsInformation)
        handleOpenShoppingCart()
      }
    } else {
      saveShoppingCart(mergedCart)
      showPromotionsValidation()
      await evaluateItmesPromotionsService(mergedCart)
      openShoppingCart()
    }
  }

  useEffect(() => {
    if (itemsToAddToCart?.length > 0) {
      const mergedCart = getAndMergeCart(itemsToAddToCart)
      updateItemsInStorage(mergedCart)
    }
  }, [])

  const handleOpenShoppingCart = async () => {
    showPromotionsValidation()
    await getPromotionItemsEvaluate()
    openShoppingCart()
  }

  return <ShoppingCartIcon onClick={handleOpenShoppingCart} />
}

export default ShoppingCartHeaderIcon
